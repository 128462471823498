
import { mixinFormato } from "@/mixins/mixinFormato";
import { mixinComp } from "@/mixins/mixinComp"

export var mixinCtrls = {
  mixins: [mixinFormato, mixinComp],

  data() {
    return {
      app:this.$store.state.G.APP,
      rol:this.$store.state.G.ROL,
      rolId:this.$store.state.G.ROLID,
      usu:this.$store.state.G.USUARIO,
      zona:this.$store.state.G.ZONA,
      apdId: (this.$store.state.G.ROL=='apds' ? this.$store.state.G.ROLID : 0),
      ndep:this.$store.state.G.USUDAT.ndep
    };
  },

  methods: {
    // paso valores del record a los controles
    async record2ctrl(record, schema, ini = false) {
      console.log("--- record2ctrl (Mixin) ---", record, schema, ini);

      // recorro el schema y le asigno el valor del record al control
      var ctrl;
      for (var key in schema) {
        ctrl= schema[key];

        // COMPRUEBO SI ES UN CTRL EN FORMATO NUEVO
        if (schema[key][0]) {
          // tiene componente?
          if (schema[key][4] && schema[key][4].comp) {
            // el componente es el responsable de meter en  el schema lo que sea necesario
            // filtro por tipo de componente
            switch (schema[key][4].comp.type) {
            case 'pickV1':
              this.pickSetNV({ ctrl:schema[key], ctrls:schema, record, sch:ini? schema : null });
              break;
            }

            continue;
          }

          // pendiente: añadir nuevos controles para el formato nuevo si es necesario

          continue;
        }

        // componentes
        if (ctrl.comp){
          // el componente es el responsable de meter en  el schema lo que sea necesario
          this[ctrl.comp.type]().set({ctrl, schema, record, ini});
          continue;
        }

        // todo lo demas
        if (ctrl.cget) {
            // el cget es responable de meter en  el schema lo que sea necesario
            //return this.cget({ctrl, schema, record, ini})
            if (!this.$isFunction(this[ctrl.cget])) continue;
            this[ctrl.cget]({ctrl, schema, record});
            continue;
        }

        ctrl.value= this.get_fieldValue({ctrl, schema, record, ini});
        if (ctrl.format) ctrl.value= this.sql2visual(ctrl, ctrl.value);
      }
    },


    //
    get_fieldValue({ctrl, schema, record, ini}) {
      if (ini) {
        if (this.$isFunction(this[ctrl.ini])){
            return this[ctrl.ini]({ctrl, schema, record});
        }

        if (ctrl.ini){
          return ctrl.ini
        }

        return "";
      }

      // no ini
      if (typeof ctrl.f=== "undefined" || typeof record[ctrl.f]=== "undefined") return "";
      return record[ctrl.f];
    },


    // paso valores de los controles a un record y lo devuelvo
    ctrl2record(ctrls, record) {
      console.log("--- ctrl2record (Mixin) ---", ctrls);
      return this.compare_record(ctrls, record);
    },


    // de ctrls a record de XM
    ctrl2linea(ctrls, record= {}) {
      console.log("--- ctrl2linea (Mixin) ---");

      Object.keys(ctrls).forEach(key => {
        if (ctrls[key].comp) record[key] = ctrls[key].comp.valueaux;

        if (!ctrls[key].f) return;
        record[ctrls[key].f] = ctrls[key].value;
      });

      return record;
    },


    // monto record_aux, comparo con record y devuelvo campos que han cambiado
    compare_record(schema, record) {
      var record_aux= {};
      var ctrl;
      Object.keys(schema).forEach(key => {
        ctrl= schema[key];
        if (ctrl.nosave == true) return;

        // TEMPORAL
        // COMPRUEBA SI ES UN SCHEMA CON LA NUEVA VERSIÓN
        console.log('CR ', key, ': ', ctrl);
        if (ctrl[0]) {
          console.log('CONTROL ', key, ' CON NUEVO FORMATO... ');

          // tiene componente?
          if (ctrl[4] && ctrl[4].comp) {
            console.log('TIENE COMPONENTE...');
            // el componente es el responsable de meter en  el schema lo que sea necesario
            // filtro por tipo de componente
            switch (schema[key][4].comp.type) {
            case 'pickV1':
              console.log('pickV1...');
              record_aux[ctrl[0]]= ctrl[2];              
              break;
            }            
            
            return;
          }
          
          // pendiente: añadir nuevos controles para el formato nuevo si es necesario

          return;
        }

        // guardo el/los dato/s del componente en el record_aux
        if (ctrl.comp) {
          this[ctrl.comp.type]().get({ ctrl, schema, record, record_aux });
          return;
        }

        // si no tiene f, salgo
        if (!ctrl.f) return;

        // para todo lo que no es componente
        // el cset es responable de guardar en el record_aux lo necesario incluido formato
        if (ctrl.cset) {
          if (!this.$isFunction(this[ctrl.cset])) return;
          this[ctrl.cset]({ ctrl, schema, record, record_aux });
          return;
        }

        record_aux[ctrl.f]= ctrl.value;
        if (ctrl.format) {
          record_aux[ctrl.f] = this.visual2sql(ctrl, ctrl.value);
        }
      });
      console.log('record_aux antes de data_update: ', JSON.parse(JSON.stringify(record_aux)));
      // comparo record_aux con record y devuelvo los campos que han cambiado
      var data_update = {};
      Object.keys(record_aux).forEach(key => {
        if (record_aux[key]== "" && record[key]== undefined) return;
        if (record_aux[key]== undefined && record[key]== "") return;
        if (record_aux[key]!= record[key]) data_update[key]= record_aux[key];
      });

      console.log('record_aux despues de data_update: ', JSON.parse(JSON.stringify(data_update)));

      return data_update;
    },

    

    //
    // paso de controles a record sin hacer un compare
    ctrl2record_wo_compare(schema, record) {
      console.log('------------ CTRL2RECORD_WO_COMPARE ---------------------');
      var record_aux= {};
      var ctrl;
      Object.keys(schema).forEach(key => {
        ctrl= schema[key];
        if (ctrl.nosave == true) return;

        // guardo el/los dato/s del componente en el record_aux
        if (ctrl.comp) {
          this[ctrl.comp.type]().get({ ctrl, schema, record, record_aux });
          return;
        }

        // si no tiene f, salgo
        if (!ctrl.f) return;

        // para todo lo que no es componente
        // el cset es responable de guardar en el record_aux lo necesario incluido formato
        if (ctrl.cset) {
          if (!this.$isFunction(this[ctrl.cset])) return;
          this[ctrl.cset]({ ctrl, schema, record, record_aux });
          return;
        }

        record_aux[ctrl.f]= ctrl.value;
        if (ctrl.format) {
          record_aux[ctrl.f] = this.visual2sql(ctrl, ctrl.value);
        }
      });
      console.log('record ctrl2record_wo_compare: ', JSON.parse(JSON.stringify(record_aux)));
      return record_aux;
    },


    //
    par_ctrls_valor(schema)
    {
      var datos_filtro = {};
      var ctrls = schema? schema.ctrls : this.schema.ctrls;

      // recorro el array de controles del Filtro recibido y voy guardando los valores
      // de los campos que tengan la propiedad 'f' definida
      for (const key in ctrls) {
        if (ctrls[key].nosave) continue;
        if (ctrls[key].f) datos_filtro[ctrls[key].f] = ctrls[key].value;
      }

      return datos_filtro;
    },


    //
    // comparo records del Grid con los definidos en su store y
    // compruebo si hay lineas duplicadas
    check_datosLineas() {
      if (!this.$store.state[this.stNameHijo] || !this.miStHijo) return false;

      // guardo records Grid y el del exp_presLin_F
      var records= JSON.parse(JSON.stringify(this.$store.state[this.stNameHijo].records));
      var recordsGrid= JSON.parse(JSON.stringify(this.miStHijo.records));

      // comparo lineas records
      console.log('records check: ', records, recordsGrid);
      for (let x=0; x< records.length; x++) {
        if (typeof recordsGrid[x]=== 'undefined') return false;
        if (JSON.stringify(records[x])!== JSON.stringify(recordsGrid[x])) return false;
      }

      // compruebo si hay lineas duplicadas
      const busqueda= recordsGrid.reduce((acc, elem) => {
        const clave = JSON.stringify({ prod_id: elem.prod_id, namea:elem.namea });
        acc[clave] = ++acc[clave] || 0;
        return acc;
      }, {});

      const duplicados= recordsGrid.filter((elem) => {
        return busqueda[JSON.stringify({ prod_id: elem.prod_id, namea:elem.namea })];
      });

      console.log('DUPLICADOS: ', duplicados);
      if (duplicados.length) return false;

      return true;
    },


    // actualizo el value del control con cada pulsación del teclado
    // si tiene una configuración de validación, valido los datos
    onInput(value, ctrl) {
      ctrl.value = value;
      /* fn_generales.check_validate(ctrl); */
    },

    // actualizo el valor del control en el focusOut del control
    // si tiene una función definida en el schema
    onChange(value, ctrl) {
      ctrl.value = value;
      if (this.$isFunction(this[ctrl.change])) this[ctrl.change](ctrl);
    },


    // configuro el estado del btn recibo
    // view:true/false, disabled:true/false
    btnSet(accion, {view= null, disabled=null}) {
      let btn= typeof accion=== "object"? accion : this.findBtra(accion);
      if (!btn) return {};

      if (view!= null) this.$set(btn, 'view', view);
      if (disabled!= null) this.$set(btn, 'disabled', disabled);
    },


    // modifico los parámetros del btn recibo
    // según el objeto 'mods'
    btnReset(accion, mods) {
      let btn= this.findBtra(accion);
      if (!btn) return;

      for (var key in mods) {
        this.$set(btn, key, mods[key]);
      }
    },


    // Pendiente: comprobar si finalmente se queda así
    pesoGet(accion) {
      let btn= this.findBtra(accion);

      if (btn== null) return 2;
      return btn.show? 1 : 0;
    },


    // busco el btn recibido en los extra de footer y mto
    // si lo encuentro devuelvo el btn completo, si no, devuelvo null
    findBtra(accion) {
      let r= null;

      // compruebo si es un mto
      if (typeof accion=== 'number') {
        r= this.Entorno.btra.footer.mto.filter(elem=> elem.accion== accion)
        return r.length? r[0] : null;
      }

      // botonera de footer
      if (this.Entorno.btra.footer.extra) {
        r= this.Entorno.btra.footer.extra.filter(elem=> elem.accion== accion)
        if (r.length) return r[0];
      }

      // botonera de row
      if (!this.Entorno.btra.row.extra) return null;
      r= this.Entorno.btra.row.extra.filter(elem=> elem.accion== accion)
      if (r.length) return r[0];
      return null;
    },


    // recalculo datos de fras
    // recalculo datos compra / venta
    recalcula_fras(ctrls, records) {

      // reseteo campos
      this.record2ctrl({}, ctrls, true);

      if (!records.length) return;

      records.map(elem=> {
        //console.log('ELEM:::: ', elem);
        if (elem.aceptada!='1') return;

        console.log('ELEM2:::: ', elem);

        if (elem.tipo_ope=='V') {
          ctrls.serv_v.value= Number(ctrls.serv_v.value) + Number(elem.base) + Number(elem.sup) - Number(elem.tmt);
          ctrls.tram_v.value= Number(ctrls.tram_v.value) + Number(elem.tmt) - Number(elem.tmt3);
          ctrls.apd_v.value= Number(ctrls.apd_v.value) + Number(elem.tmt3);

        } else if (elem.tipo_ope=='C') {
          ctrls.serv_c.value= Number(ctrls.serv_c.value) + Number(elem.base) + Number(elem.sup);

          if (elem.suplido==0) ctrls.sup.value= Number(ctrls.sup.value) + Number(elem.sup);
          ctrls.dif_sup_fac.value= Number(ctrls.dif_sup_fac.value) + Number(elem.supdif);
          ctrls.sup_fac.value= Number(ctrls.sup_fac.value) + Number(elem.supfra);
          if (elem.suplido==1) ctrls.pd.value= Number(ctrls.pd.value) + Number(elem.sup);

          if (elem.suplido==0 && Number(elem.sup)==Number(elem.imp) && Number(elem.sup)<0 && Number(elem.imp)==-Number(elem.supfra)) {
            ctrls.serv_c.value= (Number(ctrls.serv_c.value) - Number(elem.sup)).toFixed(2);
            ctrls.sup.value= (Number(ctrls.sup.value) - Number(elem.sup)).toFixed(2);
          }
        }
      });

      ctrls.total_v.value= Number(ctrls.serv_v.value) + Number(ctrls.tram_v.value) + Number(ctrls.apd_v.value);

      //ctrls.dif_cv.value= (Number(ctrls.serv_v.value) - Number(ctrls.serv_c.value) + Number(ctrls.sup_fac.value)).toFixed(2);
      ctrls.dif_cv.value= (Number(ctrls.serv_v.value) - Number(ctrls.serv_c.value)).toFixed(2);
      ctrls.dif.value= (Number(ctrls.serv_v.value) - Number(ctrls.serv_c.value) + Number(ctrls.dif_sup_fac.value)).toFixed(1);
      ctrls.bp.value= Number(Number(ctrls.total_v.value) - Number(ctrls.serv_c.value));
    },


    // TEMPORAL PARA CONTROLES CON LA NUEVA VERSION
    async pickSetNV({ctrl, record, sch}) {
      console.log('pickSetNV: ', ctrl, record, sch);
      // si no tiene f, salgo
      if (!ctrl[0]) return;

      // si es un ini, actualizo valor del record con el dato del ini
      if (sch) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];

      // si el valor de ctrl en el record está vacío, inicializo datos y salgo
      if (record[ctrl[0]]=== "" || record[ctrl[0]]=="0") {
        this.$set(ctrl, 2, "0");
        this.$set(ctrl[4].comp, 'valueAux', "");
        return;
      }

      // compruebo si existe el campo pick en el record
      // si existe, lo cojo, si no, ejecuto api
      if (typeof record["name_" + ctrl[0]]!== "undefined") {
        console.log("Obtengo Pick " + ctrl[0] + ": "+record[ctrl[0]]+" del Record, NO de la API... ", record["name_" + ctrl[0]]);
        this.$set(ctrl, 2, record[ctrl[0]]);
        this.$set(ctrl[4].comp, 'valueAux', record["name_" + ctrl[0]]);
        return;
      }



      // ejecuto api pick
      var args = { tipo: "comp", api: "pick", accion: "get", fn_args: {
          name: ctrl[4].comp.api,
          id: record[ctrl[0]],
          source: ctrl[4].comp.source? ctrl[4].comp.source : ""
        }
      };

      //   pendiente control de errores
      console.log('args pick: ', args);
      var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      console.log('apiResult pick: ', apiResult);
      // no he recibido datos
      if (apiResult.sql.error) {
        this.$set(ctrl, 2, "0");
        this.$set(ctrl[4].comp, 'valueAux', "(error " + record[ctrl[0]] + " no encontrado)");
        return;
      }

      // he recibido datos
      this.$set(ctrl, 2, record[ctrl[0]]);
      this.$set(ctrl[4].comp, 'valueAux', apiResult.r[0][0][ctrl[4].comp.f_aux]);

      // si hemos definido una fn en el schema, la ejecuto pasándole el row
      //if (ctrl.fn) this[ctrl.fn](apiResult.r[0][0]);
    }


  }
};
