export var mixinFormato = {
  methods: {
    sql2visual: function(item, dato) {
      if (!dato) return dato;
      var p, h;

      // obtengo el caracter para separar la fecha
      var sep = this.$store.state.separador_fecha;

      switch (item.format) {
        case "fh":
          // convierto fecha de sql a visual
          p = dato.split("-");
          if (p[1].length === 1) p[1] = "0" + String(p[1]);
          if (p[0].length === 1) p[0] = "0" + String(p[0]);

          return [p[2], p[1], p[0]].join(sep);

        case "fhhr":
          // convierto fecha-hora de sql a visual
          var [sdate, stime] = dato.split(" ");
          p = sdate.split("-");
          h = stime.split(":");

          return `${[p[2], p[1], p[0]].join(sep)} ${[h[0], h[1]].join(':')}`;
      }

      return dato;
    },

    visual2sql: function(item, dato) {
      // paso dato recibido a formato sql y lo devuelvo
      var p;
      if (dato==null || dato==undefined) return;

      switch (item.format) {
        case "fh":
          // convierto fecha de visual a sql
          p = dato.split(/\D/g);
          return [p[2], p[1], p[0]].join("-");

        case "fhhr":
          // convierto fecha-hora de visual a sql
          p = dato.split(/\D/g);
          return (
            [p[2], p[1], p[0]].join("-") + " " + [p[3], p[4], p[5]].join(":")
          );
      }

      return dato;
    },

    
    // devuelvo los años de diferencia entre 2 fechas
    calc_edad: function(item, fh) {     
      if (!fh) return null;

      //let fh1= new Date(this.sql2visual({format:'fh'}, this.actualDate())).getTime(); 
      
      /* 
      let fh2= new Date(this.sql2visual({format:'fh'}, fh)).getTime(); 
      let dif= fh1 - fh2;
      console.log('fechas: ', this.actualDate(), fh);
      console.log('ppp: ', fh1, fh2, dif);
      return Math.floor(dif / (1000*60*60*24));      */
    },


    // devuelvo la fecha actual en formato base datos
    actualDate() {
      const fecha = new Date();
      return `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, "0")}-${String(fecha.getDate()).padStart(2, "0")}`;
    },

    // devuelvo la fecha/hora actual en formato base datos
    actualDateTime() {
      const fecha = new Date();
      return `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, "0")}-${String(fecha.getDate()).padStart(2, "0")} ${String(fecha.getHours()).padStart(2, "0")}:${String(fecha.getMinutes()).padStart(2, "0")}:${String(fecha.getSeconds()).padStart(2, "0")}`;    
    },

    //
    identificacion(prefix= "[", sufix="]") {
      const fecha = new Date();
      const sep = "-";

      return `${prefix}${this.$store.state.G.USUARIO} ${String(fecha.getHours()).padStart(2, "0")}:${String(fecha.getMinutes()).padStart(2, "0")} ${String(fecha.getDate()).padStart(2, "0")}${sep}${String(fecha.getMonth() + 1).padStart(2, "0")}${sep}${fecha.getFullYear()}${sufix}`;
    }

  }
};
