export var mixinComp = {  

  methods: {    

    // COMPONENTE PICK **********************************************************************************************************************************
    pick() {
      let self= this;

      return {
        // devuelvo datos a guardar        
        get({ ctrl, record_aux }) {
          record_aux[ctrl.f]= ctrl.value;
        },   
                
        // muestro datos
        async set({ctrl, record, ini}) {             
          // si no tiene f, salgo
          if (!ctrl.f) return;
          
          // si es un ini, actualizo valor del record con el dato del ini
          if (ini) record[ctrl.f]= typeof ctrl.ini=== "undefined"? "" : ctrl.ini;

          // si el valor de ctrl en el record está vacío, inicializo datos y salgo
          if (record[ctrl.f]== "" || record[ctrl.f]== "0") {
            ctrl.value= "0";
            ctrl.comp.valueaux = "";
            return;
          }
    
          // compruebo si existe el campo pick en el record
          // si existe, lo cojo, si no, ejecuto api      
          if (typeof record["name_" + ctrl.f]!== "undefined") {
            console.log("Obtengo Pick " + ctrl.f + ": "+record[ctrl.f]+" del Record, NO de la API... ", record["name_" + ctrl.f]);
            ctrl.value= record[ctrl.f];
            ctrl.comp.valueaux = record["name_" + ctrl.f];
            return;
          }         
       
          // ejecuto api pick
          var args = { tipo: "comp", api: "pick", accion: "get", fn_args: {
              name: ctrl.comp.api,
              id: record[ctrl.f],
              source: ctrl.comp.source? ctrl.comp.source : ""
            }
          };
          console.log('args pick: ', args);
          var apiResult = await self.$store.dispatch("ajaxRequest", { args: args });
          console.log('apiResult pick: ', apiResult);
          // no he recibido datos
          if (apiResult.sql.error) {
            ctrl.value= "0";
            ctrl.comp.valueaux = "(error " + record[ctrl.f] + " no encontrado)";
            return;
          }
    
          // he recibido datos          
          ctrl.value= record[ctrl.f];
          ctrl.comp.valueaux = apiResult.r[0][0][ctrl.comp.f_aux];      
          
          // si hemos definido una fn en el schema, la ejecuto pasándole el row
          //if (ctrl.fn) this[ctrl.fn](apiResult.r[0][0]);
        }

      };    
     
    },


    // COMPONENTE PICK MULTIPLE *************************************************************************************************************************
    pick_multiple() {
      let self= this;

      return {       
        //
        get({ ctrl, record_aux }) {
          record_aux[ctrl.f]= ctrl.value;
        },   

        //
        async set({ctrl, record, ini, forzar=false}) {           
          // si no tiene f, salgo
          if (!ctrl.f) return; 
          
          // si es un ini, actualizo valor del record con el dato del ini
          if (ini) record[ctrl.f]= typeof ctrl.ini=== "undefined"? "" : ctrl.ini;
          
          // si el valor de ctrl en el record está vacío, inicializo datos y salgo
          if (record[ctrl.f]== "" || record[ctrl.f]== "0") {
            ctrl.value= "0";
            ctrl.comp.valueaux = "";
            return;
          }

          // compruebo si existe el campo pick en el record
          // si existe, lo cojo, si no, ejecuto api      
          if (!forzar && typeof record["name_" + ctrl.f]!== "undefined") {
            console.log("Obtengo Pick Multiple " + ctrl.f + ": "+record[ctrl.f]+" del Record, NO de la API... ", record["name_" + ctrl.f]);
            ctrl.value= record[ctrl.f];
            ctrl.comp.valueaux = record["name_" + ctrl.f];
            return;
          }         
          
          // obtengo array de datos y filtro por el valor del ctrl asociado          
          var A_elems = ctrl.comp.source? ctrl.comp.source : [];
          var elem = A_elems.filter(e => e.id === record[ctrl.comp.f2]);
          if (elem.length <= 0) {
            ctrl.value= "0";
            ctrl.comp.valueaux = "";
            return;
          }
          
          // llamada a la API
          var args = {
            tipo: "comp",
            api: "pick",
            accion: "get",
            fn_args: { name: ctrl.comp.api, id: record[ctrl.f], tabla: elem[0].tabla }
          };
      
          var apiResult = await self.$store.dispatch("ajaxRequest", { args: args });      
          if (apiResult.sql.error) {
            ctrl.value= "0";
            ctrl.comp.valueaux = "(error " + record[ctrl.f] + " no encontrado)";
            return;
          }

          // he recibido datos
          ctrl.value= record[ctrl.f];
          ctrl.comp.valueaux = apiResult.r[0][0][ctrl.comp.f_aux];
          ctrl.comp.finder = elem[0].finder;  
          return apiResult.r[0][0];
        },
    
      };    
     
    },


    // COMPONENTE FECHA *********************************************************************************************************************************
    fecha() {
      return { 
        //     
        get({ ctrl, record_aux }) {
          if (ctrl.value== "") {
            record_aux[ctrl.f]= "";
            return;
          }
                    
          record_aux[ctrl.f]= ctrl.value;
        },   
        
        //
        set({ctrl, record, ini}) {        
          if (ini) record[ctrl.f]= typeof ctrl.ini=== "undefined"? "" : ctrl.ini;   
          console.log('RCAA: ', record[ctrl.f]);  

          if (record[ctrl.f]!= null && record[ctrl.f]!= undefined) { 
            if (record[ctrl.f].includes('0000')) {           
              ctrl.value= "";
              record[ctrl.f]= "";
              return;
            }
          } 
                    
          ctrl.value= record[ctrl.f]== null? "" : record[ctrl.f];       
        }        

      }
    },


    // COMPONENTE GEOLOCALIZACIÓN ***********************************************************************************************************************
    geo() {
      //let self= this;

      return { 
        //     
        get({ ctrl, record_aux }) {
          record_aux[ctrl.f]= ctrl.value;
        },   
        
        //
        set({ctrl, record, ini}) {  
          if (ini) record[ctrl.f]= typeof ctrl.ini=== "undefined"? "" : ctrl.ini;
          ctrl.value= record[ctrl.f];
        }       

      }
    },


    // COMPONENTE OBSERVACIONES *************************************************************************************************************************
    obs() {
      return {   
        //  
        get({ ctrl, record_aux }) {
          record_aux[ctrl.f]= ctrl.value;
          //record[schema.f]== schema.comp.value + '\n' + schema.value;
        },   
        
        //
        set({ctrl, record, ini}) {  
          if (ini) record[ctrl.f]= typeof ctrl.ini=== "undefined"? "" : ctrl.ini;
          ctrl.value= record[ctrl.f];
        }      

      }
    },
    // COMPONENTE OBSERVACIONES 3 ***********************************************************************************************************************
    obs3() {
      let self = this;
      return {
        //  
        get({ ctrl, record, record_aux }) {
  
          console.log("estoy en obs3 get", ctrl, ctrl.label, ctrl)
          let texto='';
          ctrl.value='';
          //
          // field donde grabar el dato segun ROL
          let field = ctrl.comp.fields[self.$store.state.G.ROL];
  
          // obs3 con opciones de motivos tiene data como propiedad 
          if (ctrl.comp.data) {
            texto = ctrl.comp.data.texto;
            if (texto != null) texto.trim();
            if (texto == '') return;
            texto = texto.slice().replace(/\[/g, "(");
            texto = texto.replace(/\]/g, ")");
            let encabeza = ctrl.comp.data.encabeza;
            if (encabeza != '') encabeza += '\n';
            record_aux[field] = self.identificacion() + " " + encabeza + texto + "\n" + record[field];
            // para guardar en avisos le quito la cabecera y guardo el valor en ctrl.value;
            ctrl.value=self.identificacion() + " " + texto + "\n"
            return
          }
          // obs3 sin motivos
          texto = ctrl.comp.valueaux;
          if (texto != null) texto.trim();
          if (texto == '') return;
  
          texto = texto.slice().replace(/\[/g, "(");
          texto = texto.replace(/\]/g, ")");
          record_aux[field] = self.identificacion() + " " + texto + "\n" + record[field];
  
        },
  
  
        // set({ctrl,record}) {
        set({ ctrl, schema }) {
          console.log("estoy en obs3 set", ctrl, ctrl.label, schema)
          let field = ctrl.comp.fields[self.$store.state.G.ROL];
          ctrl.comp.valueaux = ''
          ctrl.value = '';
  
          // obs3 con motivos
          if (ctrl.comp.data) {
            ctrl.comp.data = { texto: '', encabeza: '', select1: 0, select2: 0 };
            return;
          }
         // obs3 con texto
         for (field in ctrl.comp.fields)
             ctrl.value+=ctrl.comp.fields[field]
        }
  
      }
    },


    // COMPONENTE CONTROL DE CAMBIOS ***********************************************************************************************************************
    tcc() {
      //let self= this;

      return {     
        get({ ctrl, record_aux }) {
          record_aux[ctrl.f]=ctrl.comp.valueaux+ctrl.value;
        },

        //        
        set({ctrl, record, ini}) {
          if (ini) record[ctrl.f]= typeof ctrl.ini=== "undefined"? "" : ctrl.ini;
          ctrl.comp.valueaux="";
          ctrl.value= record[ctrl.f];
        }     
      }

    },


    // COMPONENTE IMAGEN ***********************************************************************************************************************
    img() {
      let self= this;

      return {     
        get({ ctrl, record_aux }) {
          if (!ctrl.f) return;    
          //console.log('FILES: ', ctrl.comp.files);            
          record_aux[ctrl.f]= ctrl.comp.files;  
        },

        //
        async set({ctrl, record, ini}) { 
          // si tiene una url inicial, la guardo y salgo 
          if (ini) {
            ctrl.value= typeof ctrl.ini=== "undefined"? "" : ctrl.ini;
            return;
          }
          
          // lamada API para obtener la url de la imagen
          let args = { tipo:'fnc', accion: 'imagendb', fn_args: { accion:'get', tip:ctrl.comp.tip, tip_id:record.id }};          
          let apiResult = await self.$store.dispatch("ajaxRequest", { args: args });
          
          // si no tiene imagen blanqueo datos
          if (apiResult.sql.error||(!apiResult.sql.error && apiResult.r[0]== '')) {
            ctrl.value= "";
            return;
          }
          
          // guardo url
          ctrl.value= self.$store.state.G.url_tmp + apiResult.r[0];          
        }     
      }

    }
   
  }
};
